<template>
	<div class="pollutionalertsFormHeader puiformheader">
		<v-row>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pollutionalerts.pollutionid')" :value="getPollutionIdValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pollutionalerts.statusid')" :value="getStatusIdValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pollutionalerts.name')" :value="getNameShipValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pollutionalerts.imo')" :value="getImoValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pollutionalerts.callsign')" :value="getCallSignValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pollutionalerts.flagstate')" :value="getFlagStateValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pollutionalerts.tier')" :value="getTierValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'pollutionalerts-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'pollutionalerts'
		};
	},
	computed: {
		getPollutionIdValue() {
			return this.model && this.model.pollutionid ? this.model.pollutionid : '-';
		},
		getStatusIdValue() {
			return this.model && this.model.status && this.model.status == 'O' ? 'Open' : 'Closed';
		},
		getNameShipValue() {
			return this.model && this.model.vesselname ? this.model.vesselname : '-';
		},
		getImoValue() {
			return this.model && this.model.imo ? this.model.imo : '-';
		},
		getCallSignValue() {
			return this.model && this.model.callsign ? this.model.callsign : '-';
		},
		getFlagStateValue() {
			return this.model && this.model.flag ? this.model.flag : '-';
		},
		getTierValue() {
			return this.model && this.model.tierdesc ? this.model.tierdesc : '-';
		}
	}
};
</script>
